window.$ = window.jQuery = require("jquery");
import Granim from 'granim';

require("owl.carousel");

$( document ).ready(function() {
    
    if($(window).scrollTop() > 50) {
        $(".header__area").addClass("active");
    } else {
        $(".header__area").removeClass("active");
    }
    
    $('.menu-open , .offcanvas-overlay').on('click', function () {
        $('.offcanvas-area , .offcanvas-overlay').addClass('active');
        $('.menu-open').addClass('hidden');
    });
    
    
    $('.menu-close , .offcanvas-overlay').on('click', function () {
        $('.offcanvas-area , .offcanvas-overlay').removeClass('active');
        $('.menu-open , .offcanvas-overlay').removeClass('hidden');
    });
    
    
    $('#mariam .team__card__visible , .offcanvas-overlay').on('click', function () {
        $('#mariam .team__info__overlay , .offcanvas-overlay').addClass('active');
    });
    $('#emily .team__card__visible , .offcanvas-overlay').on('click', function () {
        $('#emily .team__info__overlay , .offcanvas-overlay').addClass('active');
    });

    $('#annie .team__card__visible , .offcanvas-overlay').on('click', function () {
        $('#annie .team__info__overlay , .offcanvas-overlay').addClass('active');
    });

    $('#andrew .team__card__visible , .offcanvas-overlay').on('click', function () {
        $('#andrew .team__info__overlay , .offcanvas-overlay').addClass('active');
    });

    $('#kyle .team__card__visible , .offcanvas-overlay').on('click', function () {
        $('#kyle .team__info__overlay , .offcanvas-overlay').addClass('active');
    });

    $('#peter .team__card__visible , .offcanvas-overlay').on('click', function () {
        $('#peter .team__info__overlay , .offcanvas-overlay').addClass('active');
    });
    
    $('#sida .team__card__visible , .offcanvas-overlay').on('click', function () {
        $('#sida .team__info__overlay , .offcanvas-overlay').addClass('active');
    });
    
    $('#nick .team__card__visible , .offcanvas-overlay').on('click', function () {
        $('#nick .team__info__overlay , .offcanvas-overlay').addClass('active');
    });

    $('#yosh .team__card__visible , .offcanvas-overlay').on('click', function () {
        $('#yosh .team__info__overlay , .offcanvas-overlay').addClass('active');
    });
    
    $('.overlay__close , .offcanvas-overlay').on('click', function () {
        $('.team__info__overlay , .offcanvas-overlay').removeClass('active');
    });


    $(".slider__wrapper").owlCarousel({
        items: 1,
        nav: false,
        dot: false,
        loop: true,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 5000,
        animateOut: 'fadeOut',
        touchDrag: false,
        mouseDrag: false
    });
    
    if ($(window).width() < 767) {
        $("#career__framework").detach().appendTo('#join__content__holder');
    }
    $( window ).resize(function() {
        if ($(window).width() < 767) {
            $("#career__framework").detach().appendTo('#join__content__holder');
        } else {
            $("#career__framework").detach().appendTo('#career_holder');            
        }
    });
    
    $(window).on("scroll", function() {
        if($(window).scrollTop() > 50) {
            $(".header__area").addClass("active");
        } else {
            $(".header__area").removeClass("active");
        }
    });

});

var granimInstance = new Granim({
    element: '#canvas-basic',
    direction: 'left-right',
    states : {
        "default-state": {
            gradients: [
                ['#FFDB9C', '#FEC87F'],
                ['#FEC87F', '#fea77f'],
                ['#FEA77F', '#EEF7F5'],
                ['#EEF7F5', '#F5D452']
            ],
            transitionSpeed: 2000,
        },
    },
    customDirection: {
      x0: '45%',
      y0: '25px',
      x1: '45%',
      y1: '322px'
    }
});
